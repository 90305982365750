
































import { Component, Ref, Watch } from 'vue-property-decorator'
import Plyr from 'plyr'
import Color from '@/constants/color'
import IconCross from '@/assets/img/icons/cross.svg?sprite'
import ButtonIcon from '@/components/ButtonIcon.vue'
import { mixins } from 'vue-class-component'
import Popin from '@/mixins/Popin'
import gsap from 'gsap'
import { Getter, namespace, State } from 'vuex-class'
import ModuleNamespace from '@/constants/module-namespace'
import MutationRemoteType from '@/constants/mutation-remote-type'

const remoteModule = namespace(ModuleNamespace.REMOTE)

@Component({
    components: {
        IconCross,
        ButtonIcon
    }
})
export default class PopinVideo extends mixins(Popin) {
    @remoteModule.State('videoIsPlaying') remoteVideoIsPlaying!: boolean
    @remoteModule.State('videoPaused') remoteVideoPaused!: boolean
    @remoteModule.State('videoCurrentTime') remoteVideoCurrentTime!: number
    @State isLeader!: boolean

    @Getter navigationIsAllowed!: boolean

    @Ref() player!: HTMLElement

    color = Color.LIGHT
    showControls = 0
    controlsIsHide = false
    closeIsHover = false

    // eslint-disable-next-line
    protected video: any

    handleControls(): void {
        this.video.toggleControls(true)
        this.controlsIsHide = false

        clearTimeout(this.showControls)

        this.showControls = window.setTimeout(() => {
            if (!this.closeIsHover) {
                this.video.toggleControls(false)
                this.controlsIsHide = true
            }
        }, 2000)
    }

    mounted(): void {
        this.video = new Plyr(this.player, {
            autoplay: true,
            // not sure if we have to keep the `autopause` option.
            // Deactivate it helps testing the remote presentation with multiple player playing at the same time
            autopause: false,
            hideControls: false,
            muted: !this.navigationIsAllowed,
            // loadSprite: false,
            controls: ['play', 'progress', 'mute', 'current-time'],
            fullscreen: {
                enabled: false,
                fallback: true,
                iosNative: false
            },
            vimeo: {
                controls: false,
                byline: false,
                portrait: false,
                title: false,
                speed: false,
                transparent: false
            }
        })

        this.video.on('playing', this.onVideoPlaying)
        this.video.on('pause', this.onVideoPause)
        this.video.on('timeupdate', this.onVideoTimeUpdate)
        this.video.on('ended', this.onVideoEnded)

        this.video.fullscreen.enter()

        this.showControls = window.setTimeout(() => {
            if (!this.closeIsHover) {
                this.video.toggleControls(false)
                this.controlsIsHide = true
            }
        }, 3000)
    }

    beforeDestroy() {
        this.video.off('playing', this.onVideoPlaying)
        this.video.off('pause', this.onVideoPause)
        this.video.off('timeupdate', this.onVideoTimeUpdate)
        this.video.off('ended', this.onVideoEnded)
    }

    enter() {
        return gsap.timeline().from(this.$el, {
            opacity: 0
        })
    }

    leave() {
        return gsap.timeline().to(this.$el, {
            opacity: 0
        })
    }

    onVideoPlaying() {
        if (!this.isLeader) return

        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.VIDEO_IS_PLAYING, true)
        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.VIDEO_PAUSED, false)
    }

    onVideoPause() {
        if (!this.navigationIsAllowed && this.remoteVideoIsPlaying) {
            this.video.play()
        }

        if (!this.isLeader) return

        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.VIDEO_IS_PLAYING, false)
        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.VIDEO_PAUSED, true)
    }

    onVideoEnded() {
        this.close()

        if (!this.isLeader) return

        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.VIDEO_IS_PLAYING, false)
        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.VIDEO_PAUSED, false)
    }

    onVideoTimeUpdate() {
        if (!this.isLeader) return

        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.VIDEO_CURRENT_TIME, this.video.currentTime)
    }

    @Watch('remoteVideoIsPlaying')
    onRemoteVideoIsPlayingChange() {
        if (this.isLeader) return

        if (this.remoteVideoIsPlaying) this.video.play()
    }

    @Watch('remoteVideoPaused')
    onRemoteVideoPausedChange() {
        if (this.isLeader) return

        if (this.remoteVideoPaused) this.video.pause()
    }

    @Watch('remoteVideoCurrentTime')
    onRemoteVideoCurrentTimeChange() {
        if (this.isLeader || !this.video.playing || this.video.seeking) return

        const diffTime = Math.abs(this.video.currentTime - this.remoteVideoCurrentTime)

        if (diffTime < 1) return

        this.video.currentTime = this.remoteVideoCurrentTime
    }
}
