import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'
import { getResourceUrl } from '@/utils/get-resource-url'
import PopinType from '@/constants/popin-type'
import MutationRemoteType from '@/constants/mutation-remote-type'
import ModuleNamespace from '@/constants/module-namespace'

@Component
export default class Popin extends Vue {
    @Prop() type!: PopinType
    @(namespace(ModuleNamespace.REMOTE).State) popinDocument!: RoadizDocument

    @Getter navigationIsAllowed!: boolean

    get src(): string {
        return getResourceUrl(this.popinDocument.url) || ''
    }

    mounted() {
        window.addEventListener('keyup', this.onKeyUp)
    }

    beforeDestroy() {
        window.removeEventListener('keyup', this.onKeyUp)
    }

    close() {
        if (!this.navigationIsAllowed) return

        this.$store.commit(ModuleNamespace.REMOTE + '/' + MutationRemoteType.POPIN_DOCUMENT, null)
    }

    onKeyUp(event: KeyboardEvent) {
        if (event.key.toLowerCase() === 'escape') this.close()
    }
}
